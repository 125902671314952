import payinIcon from "../assets/img/payinIcon.png";
import payoutIcon from "../assets/img/payoutIcon.png";
import conciliationIcon from "../assets/img/conciliationIcon.png";
import auditIcon from "../assets/img/auditIcon.png";
import middlewareIcon from "../assets/img/middlewareIcon.png";

export const GLOBAL_CONSTANTS = {
  MORE_LOGO_PATH: "https://www.morepaymentevolution.com/src/img/logo.svg",
  API_OAUTH_DOMAIN: process.env.REACT_APP_API_OAUTH_DOMAIN,
  API_OAUTH_CLIENT_ID: process.env.REACT_APP_OAUTH_CLIENT_ID,
};

export const CATALOGUE_URL_CONSTANTS = {
  getCountriesCatalogue: "catalogue/selectOption/getCountries",
  getStatesCatalogue: "catalogue/selectOption/getStates", // ?countryId=1
  getCitiesCatalogue: "catalogue/selectOption/getCities", // ?countryId=1&stateId=1
  getDocumentTypesCatalogue: "catalogue/selectOption/getDocumentsType",
  getConnectionModesCatalogue: "catalogue/selectOption/getConnectionMode",
  getMerchantAggregatorRolesCatalogue: "catalogue/selectOption/getMerchantAggregatorRole",
  getCurrenciesCatalogue: "catalogue/selectOption/getCurrency",
  getEnabledCurrenciesByTokenCatalogue: "catalogue/selectOption/getListComboCurrency",
  getCalculationTypesCatalogue: "catalogue/selectOption/getCalculationType",
  getCalculationRangeModalitiesCatalogue: "catalogue/selectOption/getCalculationRangeModality",
  getCalculationRangeTypesCatalogue: "catalogue/selectOption/getCalculationRangeType",
  getMerchantsCatalogue: "catalogue/selectOption/getListMerchant",
  getAggregatorsCatalogue: "catalogue/selectOption/getListAggregator",
  getDataSourceConnectionsCataloge: "catalogue/selectOption/dataSourceTypeConnection",
  getActivitiesCatalogue: "catalogue/selectOption/getListActivities",
  getTaxTypesCatalogue: "catalogue/selectOption/getListTaxTypes",
  getMerchantAggregatorsCatalogue: "catalogue/merchantAggregator?page=0&size=999999&sort=name,asc",
  getAuth0Roles: "catalogue/administration/getAllRoles",
  getAmountTypesCatalogue: "catalogue/selectOption/getInputAmountTypes",
};

export const LOGIN_BOXES_LOGOS = {
  payinIcon,
  payoutIcon,
  auditIcon,
  conciliationIcon,
  middlewareIcon,
};

export const ALERT_TYPES = {
  success: "success",
  error: "danger",
};

export const MERCHANT_AGGREGATOR_ROLES = {
  aggregator: 1,
  merchant: 2,
  merchantAggregator: 3,
};

export const MANDATORY_FIELD_TYPES = {
  string: 1,
  number: 2,
  date: 3,
  select: 4,
};

export const HTTP_STATUS_CODES = {
  NoContent: 204,
};

// COLORES
export const ColorOrangeMoreMT = "#f07c00";
