import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "components/pages/Login/Login";
import "./styles.scss";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react"; 
import CustomLoader from "components/shared/CustomLoader";
import React, { useState } from "react";
import { AlertProvider } from "components/shared/AlertContext";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import { setAuthData } from "store/slices/authSlice";
import { resetAuthData } from "store/slices/authSlice";
import PasswordExpiredComponent from "components/shared/PasswordExpiredComponent";

const App = () => {
  const [passwordExpired, setPasswordExpired] = useState(false);
  const {
    isLoading,
    loginWithRedirect,
    user,
    isAuthenticated,
    error,
    getAccessTokenSilently,
  } = useAuth0();
  const dispatch = useDispatch();

  const handleLoginAgain = async () => {
    try {
      await loginWithRedirect();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!passwordExpired) {
      (async function login() {
        if (!isLoading && !user) {
          if (
            error &&
            error?.message === "Your password has expired.  Please reset it."
          ) {
            setPasswordExpired(true);
          } else {
            await handleLoginAgain();
          }
        }
        await setRolesAndPermission();
      })();
    }
  }, [isLoading, user, isAuthenticated, error]);

  const loaderStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const setRolesAndPermission = async () => {
    const token = await getAccessTokenSilently();
    if (token) {
      const decode = jwtDecode(token);
      dispatch(setAuthData(decode));
    } else {
      dispatch(resetAuthData());
    }
  };

  if (isLoading)
    return (
      <div style={loaderStyle}>
        <CustomLoader />
      </div>
    );
  if (passwordExpired) {
    return (
      <PasswordExpiredComponent
        setModal={setPasswordExpired}
        modal={passwordExpired}
      />
    );
  }

  return (
    isAuthenticated && (
      <BrowserRouter>
        <AlertProvider>
          <React.Suspense
            fallback={
              <div style={loaderStyle}>
                <CustomLoader />
              </div>
            }
          >
            <Switch>
              <Route path="/login" render={() => <Login />} />
              <Redirect to="/login" />
            </Switch>
          </React.Suspense>
        </AlertProvider>
      </BrowserRouter>
    )
  );
};

export default App;
